<template>
  <li class="preicon-links shopping-cart">
    <a href="#!" @click.prevent="goToOrder" class="mini-cart">
      <span class="circle">{{qty}}</span>
      <i class="material-icons prefix">shopping_cart</i>
    </a>
  </li>
</template>

<script>
export default {
  computed: {
    qty() {
      if (this.$store.state.cart?.specification) {
        return Object.keys(this.$store.state.cart.specification).length;
      } else {
        return 0;
      }
    }
  },
  methods: {
    goToOrder() {
      if (this.qty > 0) {
        window.location.href = '/orders/new';
      }
    }
  },
}
</script>
<template>
  <div id="cartTable" class="container row wrapper">
    <div class="col s12">
      <div>
        <div class="responsive-table">
          <table class="fix-table">
            <thead>
              <tr>
                <th width="8%" nowrap>#</th>
                <th width="10%" nowrap>圖片</th>
                <th nowrap>名稱</th>
                <th nowrap>數量</th>
                <th nowrap>符合活動</th>
                <th width="10%" nowrap>單價</th>
                <th width="10%" nowrap>小計</th>
                <th width="8%" nowrap>刪除</th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="row-list"
                v-for="(specification, index) in items.specification"
                :key="`item${index}`"
              >
                <td nowrap>No. {{ index + 1 }}</td>
                <td nowrap>
                  <a :href="specification.path">
                    <img :src="specification.item.image.pic.url" style="width: 60px" />
                  </a>
                </td>
                <td class="tal">
                  <a :href="specification.path"
                    >{{ specification.item.title }}<br />{{ specification.title }}</a
                  >
                </td>
                <td nowrap>
                  <div class="number-adjust" id="number-adjust">
                    <span @click.prevent="minus(specification.key)" class="control minus">
                      <i class="tiny material-icons">remove</i>
                    </span>
                    <span class="number" id="number">{{
                      cart.specification && cart.specification[specification.key].qty
                    }}</span>
                    <span @click.prevent="add(specification.key)" class="control add">
                      <i class="tiny material-icons">add</i>
                    </span>
                  </div>
                </td>
                <td class="tal">
                  <!-- 20230307 加入KOL標題 -->
                  <div v-if="specification.kol && specification.kol.title">
                    <span style="color: #5f7563">{{ specification.kol.title }}</span>
                  </div>
                  <!-- 20221209要求隱藏未符合條件的活動內容(修改記錄) -->
                  <div v-if="qualifyEvent(specification)">
                    <div style="color: #5f7563">
                      {{ showNowEventTitle(specification) }} -
                      {{ specification.item.discount_info }}
                    </div>
                  </div>
                </td>
                <td nowrap class="price">
                  <span>${{ showPrice(specification) }}</span>
                  <div v-if="isVip && !specification.item.now_event">(VIP折扣)</div>
                </td>
                <td nowrap class="sub-price">
                  ${{
                    cart.specification &&
                    (
                      showPrice(specification) * cart.specification[specification.key].qty
                    ).toLocaleString()
                  }}
                </td>
                <td nowrap>
                  <a class="pointer" @click.prevent="remove(specification.key)"
                    ><i class="material-icons">delete</i></a
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div id="order-meta">
          <!-- 商品總金額 -->
          <div class="order-meta-row">
            <div class="col-label">
              商品金額 (共<span id="num-length">{{ items.specification.length }}</span
              >項商品)
            </div>
            <div class="col-value">
              <span class="sub-total">${{ totalAmount.toLocaleString() }}</span>
            </div>
          </div>
          <!-- 折價劵 -->
          <div v-if="isLogin" class="order-meta-row">
            <div class="col-label">
              折價劵號:
              <input
                :value="couponNo"
                @change.prevent="checkCoupon"
                @keyup.prevent="checkCoupon"
                type="text"
                class="tac fix-width"
              />
            </div>
            <div class="col-value">
              <span class="sub-total" v-if="couponDiscount && couponDiscount != 0"
                >${{ couponDiscount }}</span
              >
              <span class="sub-total" v-if="!couponDiscount">無資料</span>
            </div>
          </div>
          <!-- 點數使用 -->
          <div v-if="hasPoints > 0" class="order-meta-row">
            <div class="col-label">
              使用紅利 ({{ hasPoints }}點):
              <button
                class="btn add-cart"
                style="height: 28px; line-height: 28px"
                @click.prevent="applyPoints"
              >
                套用
              </button>
            </div>
            <div class="col-value">
              <input
                @change.prevent="changePoints"
                @keyup.prevent="changePoints"
                type="number"
                class="tac fix-width"
                :value="usePoints"
                min="0"
                :max="hasPoints > totalAmount ? totalAmount : hasPoints"
              />
            </div>
          </div>
          <!-- 運費 -->
          <div class="order-meta-row">
            <div class="col-label small-select">
              運費 (滿{{ freeShipmentCondition }}免運):
              <select
                v-model="delivery_type"
                @change.prevent="changeDeliveryType"
                id="store-type-select"
              >
                <option value="to_store">超商取貨</option>
                <option value="to_home">宅配</option>
              </select>
            </div>
            <div class="col-value">
              <span class="sub-total">${{ freight }}</span>
            </div>
          </div>
          <!-- 應付總金額 -->
          <div class="order-meta-row">
            <div class="col-label">應付金額:</div>
            <div class="col-value">
              <span class="sub-total">${{ calculateTotal.toLocaleString() }}</span>
            </div>
          </div>
        </div>
        <!-- end of order meta -->
      </div>
    </div>
  </div>
</template>

<script>
import swal from "sweetalert";
import axios from "axios";

export default {
  props: [
    "itemsInCart",
    "freeShipmentCondition",
    "isMobile",
    "isVip",
    "vipStandardOrderDiscountRate",
    "deliveryType",
    "isLogin",
  ],
  data() {
    return {
      items: this.itemsInCart,
      hasPoints: 0,
      delivery_type: this.deliveryType,
    };
  },
  computed: {
    freight() {
      return this.$store.state.freight;
    },
    cart() {
      return this.$store.state.cart;
    },
    couponDiscount() {
      return this.$store.state.couponDiscount;
    },
    couponNo() {
      return this.$store.state.couponNo;
    },
    totalAmount() {
      let total = 0;
      this.items.specification.forEach((specification) => {
        if (this.cart.specification) {
          total += this.showPrice(specification) * this.cart.specification[specification.key].qty;
        }
      });
      return total;
    },
    usePoints() {
      return this.$store.state.usePoints;
    },
    calculateTotal() {
      let total = 0;
      total = this.totalAmount - this.couponDiscount - this.usePoints;
      if (total < 0) {
        total = 0;
      }
      return total + this.freight;
    },
  },
  methods: {
    showNowEvent(specification) {
      if (specification.item.now_event) {
        return specification.item.now_event;
      }
      return null;
    },
    qualifyEvent(specification) {
      // 如有kol則顯示false，以kol的活動為主
      if (specification.kol && specification.kol.title) {
        return false;
      }
      if (specification.item.now_event) {
        if (specification.item.now_event.event_type == "qty_discount") {
          let now_event = specification.item.now_event;
          // 只有數量符合才能返回true
          let same_event_item_specifications = this.itemsInCart.specification.filter(
            (spec) => spec.item.now_event && spec.item.now_event.id == now_event.id
          );
          // 找出同樣活動的商品的數量總和
          let same_event_item_total_qty = 0;
          same_event_item_specifications.forEach((spec) => {
            if (this.cart.specification) {
              same_event_item_total_qty += this.cart.specification[spec.key];
            }
          });
          // 如果same_event_item_total_qty大於等於now_event的settings.quanlify_qty，回傳specification.item.now_price * now_event.qualify_discount_rate
          if (same_event_item_total_qty >= now_event.qualify_qty) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      }
      return false;
    },
    showNowEventTitle(specification) {
      if (specification.item.now_event) {
        return this.showNowEvent(specification).title;
      }
      return "";
    },
    eventPrice(specification) {
      // 如果有活動，判斷活動類型，否則直接回傳原價(specification.item.price)
      let now_event = specification.item.now_event;
      if (now_event) {
        switch (now_event.event_type) {
          case "item_discount":
            return specification.item.now_price;
          case "qty_discount":
            // 計算同樣活動的商品是否有超過滿額數量，如有的話，回傳活動價格
            let same_event_item_specifications = this.itemsInCart.specification.filter(
              (spec) => spec.item.now_event && spec.item.now_event.id == now_event.id
            );
            // 找出同樣活動的商品的數量總和
            let same_event_item_total_qty = 0;
            same_event_item_specifications.forEach((spec) => {
              if (this.cart.specification) {
                same_event_item_total_qty += this.cart.specification[spec.key];
              }
            });
            // 如果same_event_item_total_qty大於等於now_event的settings.quanlify_qty，回傳specification.item.now_price * now_event.qualify_discount_rate
            if (same_event_item_total_qty >= now_event.qualify_qty) {
              return Math.round(
                specification.item.now_price * parseFloat(now_event.qualify_discount_rate)
              );
            } else {
              return specification.item.price;
            }
          default:
            return specification.item.price;
        }
      } else {
        return specification.item.price;
      }
    },
    showPrice(specification) {
      // console.log(specification, "specification");
      // 如果有kol，則以kol為主
      if (specification.kol && specification.kol.price) {
        return specification.kol.price;
      } else {
        // vip折價與活動不可同時進行
        if (specification.item.now_event) {
          return this.eventPrice(specification);
        } else if (this.isVip) {
          return Math.round(specification.item.price * this.vipStandardOrderDiscountRate);
        } else {
          return specification.item.price;
        }
      }
    },
    add(specificationKey) {
      // console.log(specificationKey);
      // 取得當前的數量
      let qty = this.cart.specification[specificationKey].qty;
      let spec = this.items.specification.find((spec) => spec.key === specificationKey);
      qty += 1;
      if (qty > spec.max_qty) {
        swal({
          title: "超過上限",
          text: "您的選擇已超過庫存上限",
          icon: "warning",
        });
      } else {
        this.$store.dispatch("setCart", {
          ...this.cart.specification,
          [specificationKey]: { qty, kol: this.cart.specification[specificationKey].kol },
        });
      }
    },
    minus(specificationKey) {
      let qty = this.cart.specification[specificationKey].qty;
      qty -= 1;
      if (qty <= 0) {
        swal({
          title: "低於下限",
          text: "數量請勿低於0。如要刪除此項商品，請點擊刪除鈕。",
          icon: "warning",
        });
      } else {
        this.$store.dispatch("setCart", {
          ...this.cart.specification,
          [specificationKey]: { qty, kol: this.cart.specification[specificationKey].kol },
        });
      }
    },
    remove(specificationKey) {
      let tempCart = { ...this.cart.specification };
      delete tempCart[specificationKey];
      // console.log(tempCart);
      this.$store.dispatch("setCart", tempCart);
      // 更新items
      this.items.specification = this.items.specification.filter(
        (spec) => spec.key !== specificationKey
      );
      if (this.items.specification.length === 0) {
        swal({
          title: "您的購物車是空的",
          text: "請先選擇商品",
          icon: "warning",
        }).then(() => {
          window.location.href = "/items";
        });
      }
    },
    changePoints(e) {
      const value = e.target.value;
      if (parseInt(value) > this.hasPoints) {
        swal({
          title: "設定錯誤",
          text: "您的紅利點數小於您的設定值",
          icon: "warning",
        });
        this.$store.dispatch("setUsePoints", this.hasPoints);
      } else {
        if (value) {
          this.$store.dispatch("setUsePoints", parseInt(value));
        } else {
          this.$store.dispatch("setUsePoints", 0);
        }
      }
    },
    checkCoupon(e) {
      const value = e.target.value;
      if (value.length >= 5) {
        this.$store.dispatch("setCouponDiscount", value);
      }
    },
    changeDeliveryType() {
      this.$store.dispatch("setDeliveryType", this.delivery_type);
      if (this.delivery_type === "to_home") {
        $("#choose-store").hide();
        $("#order_receiver_address").attr("readonly", false);
        $("#order_receiver_address").prop("placeholder", "請輸入您的地址...");
        if ($("#same_as_payer").prop("checked")) {
          $("#order_receiver_address").val($("#order_payer_address").val());
        } else {
          $("#order_receiver_address").val("");
        }
        // 關閉付款方式中的超商付款取貨
        $("#order_pay_type_credit").prop("checked", true);
        $("#order_pay_type_store_collect").prop("disabled", true);
        // $("#order_delivery_type_to_home").prop("checked", true);
        $("#order_delivery_type").val("to_home");
        $("#pay_type_name_store_collect").css("text-decoration", "line-through");
      } else {
        $("#choose-store").show();
        // 清空收件人地址
        $("#store").val("");
        $("#order_receiver_address").val("");
        $("#order_receiver_address").prop("placeholder", "請選擇超商...");
        $("#order_receiver_address").attr("readonly", true);
        $("#order_pay_type_store_collect").prop("disabled", false);
        // $("#order_delivery_type_to_store").prop("checked", true);
        $("#order_delivery_type").val("to_store");
        $("#pay_type_name_store_collect").css("text-decoration", "none");
      }
    },
    applyPoints() {
      if (this.hasPoints >= this.totalAmount) {
        this.$store.dispatch("setUsePoints", this.totalAmount);
      } else {
        this.$store.dispatch("setUsePoints", this.hasPoints);
      }
    },
  },
  mounted() {
    const pointsUrl = "/carts/points";
    axios
      .get(pointsUrl)
      .then(({ data }) => {
        this.hasPoints = data.points;
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>

import Vue from "vue/dist/vue.esm";
import Vuex from "vuex";
import axios from "axios";

var csrf = $("meta[name=csrf-token]").attr("content");
axios.defaults.headers.common["X-CSRF-Token"] = csrf;

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    cart: {},
    freight: 0,
    usePoints: 0,
    couponDiscount: 0,
    couponNo: "",
    delivery_type: "to_store",
  },
  mutations: {
    setCart: (state, payload) => {
      state.cart = payload;
      return state;
    },
    setFreight: (state, payload) => {
      state.freight = payload;
      return state;
    },
    setUsePoints: (state, payload) => {
      state.usePoints = payload;
      return state;
    },
    setCouponDiscount: (state, payload) => {
      state.couponDiscount = payload;
      return state;
    },
    setCouponNo: (state, payload) => {
      state.couponNo = payload;
      return state;
    },
    setDeliveryType: (state, payload) => {
      state.delivery_type = payload;
      return state;
    },
  },
  actions: {
    setCart: (context, payload) => {
      axios.post("/carts/set", { cart: payload }).then(({ data }) => {
        // console.log(data)
        if (data.status == "success") {
          context.commit("setCart", JSON.parse(data.cart));
          context.commit("setFreight", data.freight);
          context.commit("setCouponDiscount", data.coupon_discount);
          context.commit("setCouponNo", data.coupon_no);
        }
      });
    },
    getCart: (context) => {
      axios.get("/carts").then(({ data }) => {
        // console.log(data);
        if (data.status == "success") {
          context.commit("setCart", data.cart);
          context.commit("setFreight", data.freight);
          context.commit("setUsePoints", data.use_points);
          context.commit("setCouponDiscount", data.coupon_discount);
          context.commit("setCouponNo", data.coupon_no);
        }
      });
    },
    setUsePoints: (context, payload) => {
      axios.post("/carts/set_points", { use_points: payload }).then(({ data }) => {
        if (data.status == "success") {
          context.commit("setUsePoints", payload);
          context.commit("setFreight", data.freight);
        }
      });
    },
    setCouponDiscount: (context, payload) => {
      const url = "/carts/coupon_check";
      // 當長度超過5才進行檢查
      context.commit("setCouponNo", payload);
      axios
        .post(url, { coupon: payload })
        .then(({ data }) => {
          console.log(data);
          if (data.status === "success") {
            // coupon折價金額
            context.commit("setCouponDiscount", data.coupon_discount);
            context.commit("setFreight", data.freight);
          } else {
            context.commit("setCouponDiscount", null);
            context.commit("setFreight", data.freight);
          }
        })
        .catch((error) => {
          context.commit("setCouponNo", "");
          context.commit("setCouponDiscount", null);
          context.commit("setFreight", data.freight);
          console.log(error);
        });
    },
    setDeliveryType: (context, payload) => {
      const url = "/carts/set_delivery_type";
      context.commit("setDeliveryType", payload);
      axios.post(url, { delivery_type: payload }).then(({ data }) => {
        if (data.status == "success") {
          context.commit("setFreight", data.freight);
        }
      });
    },
  },
});

export default store;

<template>
  <div>
    <div v-if="qty && qty > 0" class="number-adjust">
      <span class="control minus" @click.prevent="minus"
        ><i class="tiny material-icons">remove</i></span
      >
      <span class="number">{{ qty }}</span>
      <span class="control add" @click.prevent="add"><i class="tiny material-icons">add</i></span>
    </div>
    <div v-if="!qty">
      <h4>商品備貨中</h4>
    </div>
    <div v-if="specifications.length > 0" class="custom-select" style="width: 200px">
      <select v-model="selectedSpecification" @change.prevent="selectSpecification">
        <option
          v-for="(specification, index) in specifications"
          :key="`specification${index}`"
          :value="specification.key"
        >
          {{ specification.title }}
        </option>
      </select>
    </div>
    <div v-if="specifications.length > 0" class="section-block">
      <div class="inquery-btn">
        <button @click.prevent="addToCart" class="btn" type="button">加入購物車</button>
      </div>
      <div class="inquery-btn">
        <button @click.prevent="goToOrder" class="btn" type="button" :disabled="processing">
          <span v-show="!processing">立即結賬</span>
          <span v-show="processing">處 理 中....</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import swal from "sweetalert";
export default {
  props: ["specifications", "item", "kol"],
  data() {
    return {
      selectedSpecification: (this.specifications[0] && this.specifications[0].key) || null,
      qty: this.specifications[0]?.qty > 0 ? 1 : null,
      processing: false,
    };
  },
  computed: {
    cart() {
      return this.$store.state.cart;
    },
  },
  methods: {
    callFbq() {
      if (typeof fbq !== "undefined") {
        const { title, item_no, price } = this.item;
        fbq("track", "AddToCart", {
          content_name: title,
          content_ids: [item_no],
          content_type: "product",
          value: price,
          currency: "TWD",
        });
      }
    },
    selectSpecification() {
      const currentSpecification = this.specifications.find(
        (specification) => specification.key === this.selectedSpecification
      );
      // console.log(currentSpecification, this.selectedSpecification)
      // 判斷qty
      if (currentSpecification.qty > 0) {
        // 判斷是否已在購物車
        if (this.cart.specification[this.selectedSpecification]) {
          this.qty = this.cart.specification[this.selectedSpecification];
        } else {
          this.qty = 1;
        }
      } else {
        this.qty = null;
      }
    },
    add() {
      if (this.qty === null) {
        swal({
          title: "此規格已售完！",
          text: "",
          icon: "warning",
        });
      } else {
        // 找出specification的最大值
        const currentSpecification = this.specifications.find(
          (specification) => specification.key === this.selectedSpecification
        );
        if (this.qty < currentSpecification.max_qty) {
          this.qty++;
        } else {
          swal({
            title: "已達購買上限",
            icon: "warning",
          });
        }
      }
    },
    minus() {
      if (this.qty === null) {
        swal({
          title: "此規格已售完！",
          icon: "warning",
        });
      } else {
        if (this.qty > 1) {
          this.qty--;
        } else {
          swal({
            title: "請選擇最少一件",
            icon: "warning",
          });
        }
      }
    },
    addToCart() {
      if (this.qty === null) {
        swal({
          title: "此規格已售完！",
          icon: "warning",
        });
      } else {
        const currentSpecification = this.specifications.find(
          (specification) => specification.key === this.selectedSpecification
        );
        if (this.qty > currentSpecification.qty) {
          swal({
            title: "數量選擇超過庫存上限",
            icon: "warning",
          });
        } else {
          this.$store.dispatch("setCart", {
            ...this.cart.specification,
            [this.selectedSpecification]: { qty: this.qty, kol: this.kol },
          });
          swal({
            title: "已加入購物車",
            icon: "success",
          });
          // pixel
          this.callFbq();
        }
      }
    },
    goToOrder() {
      if (this.qty === null) {
        swal({
          title: "此規格已售完！",
          icon: "warning",
        });
      } else {
        this.processing = true;
        this.$store.dispatch("setCart", {
          ...this.cart.specification,
          [this.selectedSpecification]: { qty: this.qty, kol: this.kol },
        });
        // pixel
        this.callFbq();
        setTimeout(() => {
          window.location.href = "/orders/new";
        }, 1500);
      }
    },
  },
  watch: {
    cart: {
      handler() {
        if (this.selectedSpecification && this.qty && this.qty > 0) {
          this.qty = this.cart.specification[this.selectedSpecification].qty || 1;
        }
      },
      deep: true,
    },
  },
};
</script>

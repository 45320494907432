import Vue from 'vue/dist/vue.esm';
// 套件
import { ValidationProvider, ValidationObserver } from 'vee-validate';
// store
import store from './store';
// 其他元件
import AddCartBtn from './components/add_cart_btn.vue';
import Minicart from './components/minicart.vue';
import CartTable from './components/cart_table.vue';

// vue init
document.addEventListener('DOMContentLoaded', () => {
  new Vue({
    el: '#vue-app',
    store,
    created() {
      // 取回購物車車資料
      this.$store.dispatch("getCart");
    }
  })
});

// 套件使用
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);

// 元件套用
Vue.component('add-cart-btn', AddCartBtn);
Vue.component('minicart', Minicart);
Vue.component('cart-table', CartTable);